<template>
  <div>
    <h1 class="mb-20">Таблица сроков и нагрузки</h1>
    <NavTabsSwiper v-if="!isLoadingTabs" mobile-in-select :tabs-list="tabs" :is-loading="isLoading" />

    <div v-if="visiblePageActions" class="flex mb-m">
      <div class="ml-auto">
        <VButton
          text="Создать таблицу"
          icon="create"
          size="sm"
          color="primary-sm"
          @click.native="openModal"
        />
      </div>
    </div>

    <Spinner v-if="isLoading" />

    <div
      v-for="(item, idx) of tables"
      :key="item.id"
      class="d-contents"
    >
      <WaitingForLaunchTable
        v-if="isWaitingForLaunchTable(item.type_id)"
        ref="WaitingForLaunchTable"
        :rows="item.table.rows"
        :sum="item.sum"
        :updated-at="item.updated_at"
        :orders-amount="item.table.order_amount"
        :visible-edit="isAdmin"
        :update-handler="
          payload => updateWaitingForLaunchTable({ payload, item })
        "
      />

      <CurrentLoadTable
        v-else-if="isCurrentLoadTable(item.type_id)"
        :ref="`CurrentLoadTable_${item.id}`"
        class="mb-l"
        :rows="item.table.rows"
        :sum="item.sum"
        :updated-at="item.updated_at"
        :visible-edit="isAdmin"
        :update-handler="payload => updateCurrentLoadTable({ payload, item, idx })"
        :remove-handler="() => removeTable({ id: item.id, idx})"
      />

      <ProductionTimeTable
        v-else
        class="mb-m"
        :head="item.table.head"
        :columns="item.table.columns"
        :rows="item.table.rows"
        :table-type="item.type_id"
        :updated-at="item.updated_at"
        :visible-edit="isAdmin || isProductionManager"
        :visible-title="idx === firstTimeTableIdx"
        :update-handler="payload => updateTimeTable({ payload, item })"
        :remove-handler="() => removeTable({ id: item.id, idx})"
      />
    </div>

    <FormCreateTable
      v-if="isModal"
      :calendar-tab-id="+$route.params.id"
      @response="addTable"
      @reject="closeModal"
    />
  </div>
</template>

<script>
import CurrentLoadTable from '@/entities/load-calendar/ui/table/CurrentLoadTable.vue'
import ProductionTimeTable from '@/entities/load-calendar/ui/table/ProductionTimeTable.vue'
import NavTabsSwiper from '@/shared/ui/tabs/NavTabsSwiper.vue'
import WaitingForLaunchTable from '@/entities/load-calendar/ui/table/WaitingForLaunchTable.vue'
import VButton from '@/components/simple/button/VButton.vue'
import { FormCreateTable } from '@/features/load-calendar/create-table'

import { LoadCalendarModel } from '@/entities/load-calendar'
import { UpdateTableModel } from '@/features/load-calendar/update-table'

import { routeName } from './route'
import { mixinIsLoading } from '@/mixins/mixinIsLoading'
import { showAlertError } from '@/utils/store/alert'
import { mapGetters, mapState } from 'vuex'
import { mixinModal } from '@/mixins/modal/mixinModal'
import { TableTimeAndLoadApi } from '@/api/calendar/TableTimeAndLoadApi'
import { byTypeId } from '@/utils/array/sort'
import { findById } from '@/utils/array/find'
import { deepClone } from '@/utils/deepClone'
import { formatNum } from '@/utils/format/number'

export default {
  name: 'ProductionTimeAndLoadPage',
  components: {
    VButton,
    WaitingForLaunchTable,
    NavTabsSwiper,
    ProductionTimeTable,
    CurrentLoadTable,
    FormCreateTable
  },
  mixins: [mixinIsLoading, mixinModal],
  provide () {
    return {
      provideData: this.provideData
    }
  },

  data: () => ({
    tables: [],
    provideData: {
      windingMethodsFiltered: []
    },
    waitingForLaunch: {
      ordersAmount: 0
    }
  }),

  computed: {
    ...mapState('handbook/tabTableTimeAndLoad', {
      isLoadingTabs: 'isLoading'
    }),
    ...mapGetters('permission', {
      isAdmin: 'isAdmin',
      isProductionManager: 'isProductionManager'
    }),
    windingMethods () {
      return this.$store.state.records.properties.winding_method
    },

    firstTimeTableIdx () {
      return this.tables.findIndex(item => {
        switch (item.type_id) {
          case LoadCalendarModel.ETableTypes.byOperation:
          case LoadCalendarModel.ETableTypes.byGOST:
          case LoadCalendarModel.ETableTypes.byCoatingType: return true
        }
        return false
      })
    },

    currentTabWindingMethodIds () {
      if (!this.currentTab) return null

      const set = new Set()

      for (const workshop of this.currentTab.workshops) {
        for (const id of workshop.winding_method_ids) {
          set.add(id)
        }
      }

      return set
    },

    currentTab () {
      if (!this.calendarTabId || !this.tabs.length) return null

      return findById(this.tabsTableTimeAndLoad, this.calendarTabId)
    },

    tabs () {
      return this.tabsTableTimeAndLoad.map(item => ({
        ...item,
        label: item.name,
        routeName,
        routeParams: { id: item.id }
      }))
    },

    tabsTableTimeAndLoad () {
      return this.$store.state.handbook.tabTableTimeAndLoad.list
    },

    visiblePageActions () {
      return (
        this.isAdmin && !this.isLoading && this.calendarTabId && !this.isTabWaitingForLaunch
      )
    },

    calendarTabId () {
      return +this.$route.params.id
    },

    isTabWaitingForLaunch () {
      return this.calendarTabId === 1
    },

    isWaitingForLaunchTable () {
      return LoadCalendarModel.isWaitingForLaunchTable
    },
    isCurrentLoadTable () {
      return LoadCalendarModel.isCurrentLoadTable
    }
  },

  watch: {
    '$route.params.id': {
      handler (id) {
        if (!id) return
        this.loadTab()
      },
      immediate: false
    },

    currentTabWindingMethodIds: {
      handler (set) {
        if (!set) {
          this.provideData.windingMethodsFiltered = []
          return
        }

        this.provideData.windingMethodsFiltered = this.windingMethods.filter(
          item => this.currentTabWindingMethodIds.has(item.id)
        )
      },
      immediate: true
    }
  },

  async created () {
    this.getTabAll()
    await this.getGOSTAll()

    if (this.calendarTabId) {
      this.loadTab()
    }
  },

  methods: {
    async loadTab () {
      try {
        this.startLoading()

        this.resetTables()

        const { data } = await TableTimeAndLoadApi.getAll({
          calendar_tab_id: this.calendarTabId
        })

        this.setTables(data)
      } catch (e) {
        showAlertError(e)
      } finally {
        this.finishLoading()
      }
    },

    addTable (response) {
      try {
        this.tables.push(LoadCalendarModel.mapTable(response.data))
        this.tables.sort(byTypeId)
        this.closeModal()
      } catch (e) {
        showAlertError(e)
      }
    },

    setTables (data) {
      data.sort(byTypeId)

      this.tables.push(
        ...data.map(LoadCalendarModel.mapTable)
      )

      this.tables.forEach(function (table) {
        if (table.sum) {
            Object.keys(table.sum).forEach(function (key) {
                table.sum[key] = formatNum(table.sum[key])
            })
        }
      })
    },

    resetTables () {
      this.tables.length = 0
    },

    async updateWaitingForLaunchTable ({ payload, item }) {
      const postData = {
        ...item,
        table: {
          rows: UpdateTableModel.getFormatWaitingForLaunchTable(payload.rows)
        }
      }

      const response = await TableTimeAndLoadApi.update(item.id, postData)

      const updatedItem = LoadCalendarModel.mapTable(response.data)
      item.updated_at = response.data.updated_at
      item.table.order_amount = updatedItem.table.order_amount
      item.table.rows = updatedItem.table.rows

      this.$nextTick(this.$refs.WaitingForLaunchTable[0].syncData)
    },

    async updateCurrentLoadTable ({ payload, item, idx }) {
      const postData = {
        ...item,
        table: {
          rows: UpdateTableModel.getFormatCurrentLoadTable(payload.rows)
        }
      }

      const response = await TableTimeAndLoadApi.update(item.id, postData)

      const updatedItem = LoadCalendarModel.mapTable(response.data)
      item.updated_at = response.data.updated_at
      item.table.rows = updatedItem.table.rows

      const key = `CurrentLoadTable_${item.id}`
      this.$nextTick(this.$refs[key][0].syncData)
    },

    async updateTimeTable ({ payload, item }) {
      const formatTable = UpdateTableModel.getFormatTimeTable({
        head: payload.head,
        columns: payload.columns,
        rows: payload.rows,
        type_id: item.type_id
      })

      const postData = {
        ...item,
        table: {
          head: formatTable.head,
          columns: payload.columns,
          rows: formatTable.rows
        }
      }

      const response = await TableTimeAndLoadApi.update(item.id, postData)

      item.updated_at = response.data.updated_at
      item.table.head = deepClone(payload.head)
      item.table.columns = deepClone(payload.columns)
      item.table.rows = deepClone(payload.rows)
    },

    async removeTable ({ id, idx }) {
      await TableTimeAndLoadApi.destroy(id)

      this.tables.splice(idx, 1)
    },

    getTabAll () {
      this.$store.dispatch('handbook/tabTableTimeAndLoad/loadList')
    },

    async getGOSTAll () {
      await this.$store.dispatch('handbook/gost/loadList')
    }
  }
}
</script>
